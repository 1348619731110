
import ApiService from "@/common/api.service"

import {GetterTree, ActionTree, MutationTree,Module} from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const LIST_COMMENTS_SEARCH = "/comments/search"
export const UPDATE_COMMENT = "updateComment"
export const DELETE_COMMENT = "deleteComment"

// mutation types

export const SET_LIST_COMMENTS = "setListComments"
export const SET_DETAIL_COMMENT = "setDetailComments"
export const SET_ERROR = "setError"


export const state = () => ({
    errors: null,
    list_comments: [],
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    listCommentsSearch:state  =>  state.list_comments
}

const mutations: MutationTree<RootState> = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_LIST_COMMENTS](state, error) {
        state.list_comments = error;
    }
}

const actions: ActionTree<RootState, RootState> = {
    [LIST_COMMENTS_SEARCH](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(LIST_COMMENTS_SEARCH, credentials)
                .then((data:any) => {
                    if (data.status) {
                        context.commit(SET_LIST_COMMENTS, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [DELETE_COMMENT](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`${API.DELETE_COMMENT}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
}
const commentModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default commentModule