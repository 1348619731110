import  { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store  from './store'
import ApiService from './common/api.service'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/tailwind.css'
import './assets/main.scss'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'
import Toaster from '@meforma/vue-toaster'
import './plugins/validate'
import i18n from './common/i18n'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue'

ApiService.init();
const app = createApp(App)
app.use(CkeditorPlugin)
app.use(store)
app.use(router)
app.use(Toaster)
app.use(i18n)

app.use(VueAxios,axios)
app.use(ElementPlus)

app.mount('#app')
app.component('EasyDataTable', Vue3EasyDataTable);
