import {createStore} from 'vuex'
import header from "./header";
import login from './login'
import testApi from './testApi'
import auth from './auth'
import user from './user'
import admin from './admin'
import company from './company'
import role from './role'
import permission from './permission'
import personalAccessKey from './personalAccessKey'
import exam from './exam'
import upload from './upload'
import examIntro from './examIntro'
import temporaryExam from './temporary_exam'
import category from './category'
import comment from './comment'
import takeExam from './take_exam'

export default createStore({
    modules:{
        testApi,
        header,
        login,
        auth,
        user,
        admin,
        company,
        role,
        permission,
        personalAccessKey,
        exam,
        upload,
        examIntro,
        temporaryExam,
        category,
        comment,
        takeExam
    },
    state: {
        currentPageName: '', 
      },
      mutations: {
        setCurrentPageName(state, name) {
          state.currentPageName = name; 
        },
      },
      actions: {
        updateCurrentPageName({ commit }, name) {
          commit('setCurrentPageName', name); 
        },
      },
      getters: {
        getCurrentPageName: (state) => state.currentPageName, 
      },
})

