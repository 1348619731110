// @ts-ignore
import ApiService from "@/common/api.service"
// @ts-ignore
import {GetterTree, ActionTree, MutationTree,Module} from 'vuex'
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types
export const GET_LIST_TAKE_EXAM = "listTakeExams"
export const GET_LIST_TAKE_EXAM_USER = "listTakeExamsUser"
export const GET_LIST_TAKE_EXAM_SEARCH = "listTakeExamsSearch"
export const DELETE_TAKE_EXAM = "deleteTakeExams"
// mutation types
export const SET_LIST_TAKE_EXAM = "getListTakeExam"

export const state = () => ({
    take_exams: [] as any
})

 type RootState = ReturnType<typeof state>
 const getters: GetterTree<RootState, RootState> = {
     take_exams :state  =>  state.take_exams
}

const mutations: MutationTree<RootState> = {
    [SET_LIST_TAKE_EXAM](state, data) {
        state.take_exams = data;
    }
}

const actions: ActionTree<RootState, RootState> = {
    [GET_LIST_TAKE_EXAM]( {commit}) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_TAKE_EXAM,'').then((data:any)=>{
                if (data.data.status) {
                    commit(SET_LIST_TAKE_EXAM, data.data.data)
                }
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            })
        })
    },
    [GET_LIST_TAKE_EXAM_SEARCH]( {commit}, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.GET_LIST_TAKE_EXAM_SEARCH, payload).then((data:any)=>{
                if (data.data.status) {
                    commit(SET_LIST_TAKE_EXAM, data.data.data)
                }
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            })
        })
    },
    [DELETE_TAKE_EXAM]( {commit},payload) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.DELETE_TAKE_EXAM,payload.id).then((data:any)=>{
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response.data);
            })
        })
    },
    [GET_LIST_TAKE_EXAM_USER]( {commit}, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.GET_LIST_TAKE_EXAM_USER}/${payload.id}`, payload).then((data:any)=>{
                if (data.data.status) {
                    commit(SET_LIST_TAKE_EXAM, data.data.data)
                }
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            })
        })
    }
}
const takeExamModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default takeExamModule
